import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const Header = () => {
  return (
    <>
        <nav className="header">
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={8}>
                        <Link to="/" className='logo'>
                            <img src="/logo/logo.svg" alt='RapidShare'/>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </nav>
    </>
  )
}

export default Header